/*
    Endeca configuration for the typeahead endeca instance.

    See comments in site.endeca.instances.search.configuration
*/

var site = site || {};
site.endeca = site.endeca || {};
site.endeca.instances = site.endeca.instances || {};
site.endeca.instances.typeahead = site.endeca.instances.typeahead || {};

jQuery(document).ready(function(){
    site.endeca.instances.typeahead.configuration = jQuery.extend( true, {}, site.endeca.configuration, {
        followRedirects                   : false,
        minSearchLength                   : 3,
		    fullSearchBaseUrl                 : "/esearch?search=",

        nodes: {
            wrapper                       : jQuery('.js-end-typeahead-wrapper'),
            inputElements                 : jQuery('.js-end-search-term'),
            popularBlock                  : jQuery('.js-end-typeahead-popular'),
            loading                       : jQuery('.js-end-typehead-loading')
        },

        queries: {
            product: {
                //searchKey                 : 'typeahead',
                recordsPerPage            : 5,
                recordFilters             : ['discontinued', 'activeSkus', 'products']
            }
        },

        results: {
            products: {
                baseClass                 : 'site.endeca.results',
                instanceArgs: {
                    parentNode            : jQuery('.js-end-typeahead-product-results', '.js-end-typeahead-wrapper'),
                    childTemplatePath     : '/templates/endeca/typeahead/product-result.tmpl'
                },
                configuration: {
                    limit: 5
                }
            },
            terms: {
                baseClass                 : 'site.endeca.results',
                instanceArgs: {
                    parentNode            : jQuery('.js-end-typeahead-term-results', '.js-end-typeahead-wrapper'),
                    templatePath          : '/templates/endeca/typeahead/term-result.tmpl'
                },
                configuration: {
                    limit: 1
                }
            },
            seeResults: {
                baseClass                 : 'site.endeca.results',
                instanceArgs: {
                    parentNode            : jQuery('.js-end-typeahead-see-results', '.js-end-typeahead-wrapper'),
                    templatePath          : '/templates/endeca/typeahead/term-result.tmpl'
                }
            }
        }
    });

    new site.endeca.instances.typeahead.control( site.endeca.instances.typeahead.configuration );
});
